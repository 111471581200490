import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reasons from "../components/reasons"

const Testimonials = props => {
  return (
    <Layout>
      <SEO title="Testimonials" />
      <main className="main-container">
        <div className="title-container">
          <h1 className="page-title">Testimonials</h1>
        </div>
        <div
          style={{ background: "none", maxWidth: 800, margin: "0 auto" }}
          className="main-content-container"
        >
          <div className="main-content-container">
            <p>
              "Like A Daughter Reno has offered my father a way of living we
              doubted he'd have at his age. Caring for him is a priority for
              myself and my siblings. However, living in different states,
              raising our individual families, and working to provide for
              ourselves and our parents is difficult to maintain. Finding Teri
              and Like a Daughter was like divine intervention. You can just
              tell she loves my dad and her helping him live his best life."
            </p>
            <p style={{ fontWeight: "bold" }}>Candice M.</p>
          </div>
          <div style={{ marginTop: "1rem" }} className="main-content-container">
            <p>
              "Like a daughter was the best thing that ever happened to our dad.
              Teri became a very important part of our family and I think he
              lived longer, and had a higher quality of life, because of her
              care and love for him. We will be forever grateful for this
              wonderful company."
              <p style={{ fontWeight: "bold" }}>Jacci T.</p>
            </p>
          </div>
        </div>
        <Reasons bottom />
      </main>
    </Layout>
  )
}

export default Testimonials
